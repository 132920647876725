import React from "react"
import Layout from "components/layout"
import SEO from "components/SEO/SEO"
import { useStaticQuery, graphql } from "gatsby"
import BlogUi from "components/Blogs/BlogUi"

function BlogPage() {
  const { markdownRemark, allMarkdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "blog section" } }) {
        frontmatter {
          title
          subtitle
        }
      }
      allMarkdownRemark(
        filter: {
          frontmatter: { category: { eq: "blog" }, published: { eq: true } }
        }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            html
            fields {
              slug
            }
            frontmatter {
              title
              description
              date(formatString: "MMM DD, YYYY")
              tags
              cover {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const posts = allMarkdownRemark.edges
  return (
    <Layout>
      <SEO title="Blog "></SEO>
      <BlogUi
        posts={posts}
        title="Blog"
        description="This are our lastest blog you can check out more in the blog section."
      />
    </Layout>
  )
}

export default BlogPage
